require('./bootstrap');

require('./plugins');

require('./global');
// filinvest custom js
require('./custom');
require('../../../../../node_modules/bootstrap-validator/dist/validator.min.js');
require('../../../../../node_modules/flexslider/jquery.flexslider-min');
require('../../../../../node_modules/bootstrap-select/dist/js/bootstrap-select.min');
require('../../../../../node_modules/vue-match-heights/dist/vue-match-heights.min');
// require('../../../../../node_modules/vue-lazyload/vue-lazyload');
require('../../../../../node_modules/vue-progressive-image/dist/vue-progressive-image');
require('../../../../../node_modules/slick-carousel/slick/slick.min');
// require('../../../../../node_modules/lazyload/lazyload.min.js');
// require('../../../../../node_modules/lozad/dist/lozad.min');
require('../../../../../node_modules/jquery.nicescroll/dist/jquery.nicescroll');
require('../../../../../node_modules/sweetalert2/dist/sweetalert2.all');
require('../../../../../node_modules/flatpickr/dist/flatpickr.min');
require('../../../../../node_modules/magnific-popup/dist/jquery.magnific-popup.min');
require('../../../../../node_modules/lazysizes/lazysizes.min');
import 'lazysizes/plugins/attrchange/ls.attrchange';
require('../../../../../node_modules/jquery-match-height/dist/jquery.matchHeight-min');
require('../../../../../node_modules/restables/restables.min');
require('../../../../../node_modules/gsap/src/minified/TweenMax.min');
require('../../../../../node_modules/scrollmagic/scrollmagic/minified/ScrollMagic.min');
// require('../../../../../node_modules/scrollmagic/scrollmagic/minified/plugins/animation.gsap.min');


require('../../../../../node_modules/readmore-js/readmore');
// require('../../../../../node_modules/parallax-imagescroll/jquery.imageScroll');

// require(['../../../../../node_modules/parallax-imagescroll/jquery.imageScroll'], function (ImageScroll) {
//     $('.parallax-element').each(function () {
//         new ImageScroll(this);
//     });
// });

// require('../../../../../node_modules/fg-loadcss/dist/cssrelpreload');
// require('../../../../../node_modules/fg-loadcss/dist/loadCSS');
// require('../../../../../node_modules/fg-loadcss/dist/onloadCSS');

// Custom JS 
require('../../../../../public/libraries/promise/es6-promise.auto.min');
require('../../../../../public/libraries/promise/es6-promise.min');
require('../../../../../public/libraries/wow/wow');

const WOW = require('../../../../../public/libraries/wow/wow');

$(window).on("load", function () {
    window.wow = new WOW({
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: true,
        live: false,
    });
    new WOW().init();
});