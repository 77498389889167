
window.pageLoaded = () => {
     // // Scroll to top on page refresh
    // $('body').removeClass('processing-page-load').animate({ scrollTop: 0 }, 800);
    // Show the main wrapper when the page is fully loaded
    // $('.main-wrapper, .main-footer').fadeTo(500, 1);
    // Remove page loading when the page is fully loaded
    // $('.page-loading').fadeTo(500, 0).removeClass('page-loading-show');
    // $('.loading').fadeTo(500, 1).removeClass('loading');
    // $('.to-load').fadeTo(500, 1)
}

window.pageLoading = () => {
    // $('.main-wrapper, .main-footer').fadeTo(500, 0);
    // $('.page-loading').fadeTo(500, 1).addClass('page-loading-show');
    // $('.to-load').fadeTo(500, 0)


     // // Scroll to top on page refresh
    // $('body').removeClass('processing-page-load').animate({ scrollTop: 0 }, 800);
    // Show the main wrapper when the page is fully loaded
    // $('.main-wrapper, .main-footer').fadeTo(500, 0);
    // Remove page loading when the page is fully loaded
    // $('.page-loading').fadeTo(500, 1).removeClass('page-loading-show');
    // $('.loading').fadeTo(500, 0).removeClass('loading');
    // $('.to-load').fadeTo(500, 0)
}

/**
 * jQuery functions
 */

$(window).on('load', function () {
    // pageLoaded()
});
// $(document).ready(function () {
//     cardForm($('.card-form'))
//     $('body').on('click', 'a[href^="http:"]:not(:disabled):not([name]),a[href^="https:"]:not(:disabled):not([name]):not([target])', () => {
//         // pageLoading()
//     })
//     swalButtons($('body'))
// });


